
@font-face {
  font-family: "Parisian"; 
  src: url(./assets/Fonts/Parisian.ttf) format("truetype");
  src: url(./assets/Fonts/Parisian.eot);
  src: url(./assets/Fonts/Parisian.woff) format("woff"),
  url(./assets/Fonts/Parisian.woff2) format("woff2"),
  url(./assets/Fonts/Parisian.svg#filename) format("svg");
}

@font-face {
  font-family: "Poppins-Regular"; 
  src: url(./assets/Fonts/Poppins-Bold.ttf) format("truetype");
  src: url(./assets/Fonts/Poppins-Light.ttf) format("truetype");
  src: url(./assets/Fonts/Poppins-Medium.ttf) format("truetype");
  src: url(./assets/Fonts/Poppins-Regular.ttf) format("truetype");
  src: url(./assets/Fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica"; 
  src: url(./assets/Fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "helvetica-light-587ebe5a59211"; 
  src: url(./assets/Fonts/helvetica-light-587ebe5a59211.ttf) format("truetype");
}

@font-face {
  font-family: "RollandinEmilie"; 
  src: url(./assets/Fonts/Rolland/RollandinEmilie.ttf) format("truetype");
}

@font-face {
  font-family: "OstrichSansInline-Regular"; 
  src: url(./assets/Fonts/Ostrich/OstrichSansInline-Regular.otf) format("truetype");
}

@font-face {
  font-family: "OstrichSans-Medium"; 
  src: url(./assets/Fonts/Ostrich/OstrichSans-Medium.otf) format("truetype");
}

/* Remove white flash before page load */

#video {
  object-fit: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}


.app {
  /* background: lightblue; */
  text-align: center;
  /* background-color:white; */
  /* background-image: url("./assets/Drawing20.mp4"); */
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
  background-color: darkgoldenrod;

	
   /* Hide scrollbar without taking up space */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.app_sub {
  background-color: rgb(67, 75, 70) ;
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
}

.pages_header {
  height: 90%;
  width: 100%;
  flex-direction: column; 
  justify-content: space-around;
  align-items: center;
  margin: 100px 0;
}

.main_page_header{
  position: fixed;
  width: 100%;
  height: 20%;
  top: -30px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
}

.main_page_header_text:hover{
    opacity: .6;
    transform: scale(1.1); 
    cursor: default;
}

.main_page_header_text{
  min-width: 5%;
  height: 10%;
  margin: 0px 10px;
}

.main_page_header_text p {
  font-size: 20px;
  font-weight: bold;
  color: darkgoldenrod;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: bold;
  filter: drop-shadow(5px 5px 10px rgba(0,0,0,1));
}

.my_pic{
  width: 500px;
  height: 500px;
  border-radius: 50%;
  object-fit:cover;
  background-size: cover;
  background-position: center;
  align-items: center;
  filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.4));
}

.my_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container{
  max-width: 80%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  border-radius: 20px;
  backdrop-filter: blur(4px);

}

.content_container{
  /* min-height: 100vh; */
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  
}
.content_container2{
  /* min-height: 100vh; */
  max-width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 100px;
  
}

.content_container3{
  /* min-height: 100vh; */
  max-height: 40%;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  background-color: rgb(53, 52, 51, .7);
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);

  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 20px;
  overflow: hidden; 
}

.header_container{
  /* min-height: 100vh; */
  max-width: 80%;
  display: grid;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 4%;
  background-color: darkgoldenrod;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.header {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 80px;
  letter-spacing: 0.1em;
  font-family: "Parisian" ;
  font-weight: bold;
  color:#424f35;
}

.header_space {
  width: 100%;
  height: 20%;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 80px;
  letter-spacing: 0.1em;
  font-family: "Parisian" ;
  font-weight: bold;
  color:#424f35;
}

.page_header_spacer{
  height: 100%;
}

.title_header {
  font-size: 22px;
  text-align: center;
  margin-top: 2%;
  letter-spacing: 0.3em;
  font-family: "Parisian" ;
  font-weight: bolder;
  text-decoration: underline;
  text-decoration-color: black;

}

.paragraph {
  font-size: 24px;
  text-align: top;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: lighter;
  text-decoration: underline;
  text-decoration-color: black;
  /* color:#79876F; */
  color:  black;
  /* color: black; */
  
}

.paragraph_descriptions {
  font-size: 24px;
  text-align: top;
  line-height: 50px;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: lighter;
  color:black;
  background-color: darkgoldenrod;
  padding: 25px;
  /* border-radius: 10px; */
}

.paragraph_descriptions2 {
  font-size: 35px;
  line-height: 30px;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: lighter;
  color:black;
  background-color: #136d70;
  padding: 15px;
  margin: 2%;
  /* border-radius: 10px; */
}

.paragraph_descriptions_title {
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: lighter;
  color:darkgoldenrod;
  padding: 15px;
  /* border-radius: 10px; */
}

.about_me_app {
  background-color: #9BCDBB;
  background-color: rgb(155,205,187);
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
}

.about_me_paragraph_descriptions {
  font-size: 35px;
  line-height: 30px;
  letter-spacing: 0.2em;
  font-family: "Parisian" ;
  font-weight: lighter;
  color:black;
  background-color: #768770;
  padding: 15px;
  margin: 2%;
  /* border-radius: 10px; */
}

.about_me {
  position: fixed;
  top: 8px;
  right: 16px;
  z-index: 10;
  margin: 60px;
  /* background:rgb(127, 141, 127, .5); */
  background:darkgoldenrod;
  opacity: 100%;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);

  animation-duration: 2s;
  animation-iteration-count: infinite;
  
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

.about_me_paragraph {
  font-family: "Helvetica" ;
  text-align: center;
  letter-spacing: 0.1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40%;
  margin-bottom: auto;
  font-size: 20px;
  color: black;
  text-decoration: underline;
  text-decoration-color: black;
  
}

.project_app {
  /* background: lightblue; */
  text-align: center;
  /* background-color:rgb(90, 90, 90); */
  background: linear-gradient(109.6deg, rgb(0,120,110) 11.2%, rgb(224, 235, 213) 91.1%);
  /* background-color: #cccccc; */
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
}

.project_type {
  position: fixed;
  top: 8px;
  left: 0px;
  z-index: 10;
  margin: 60px;
  /*background:rgb(127, 141, 127, .5); */
  width: 130px;
  height: 130px;

  /*border-radius: 50%;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */

  animation-duration: 2s;
  animation-iteration-count: infinite;
  
  /* animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); */
}

.project_type_text_container{
  margin: auto;
}

.project_paragraph {
  font-family: "OstrichSans-Medium" ;
  text-align: center;
  letter-spacing: 0.1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40%;
  margin-bottom: auto;
  font-size: 20px;
  color: darkgoldenrod;
  text-decoration: underline;
  text-decoration-color: black;
  
}

@keyframes bounce-7 {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-60px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

.icons {
  height: 50%;
  width: 200px;
  padding: 20px;
  top: -300;
  display: grid;
  grid-template-columns: .5fr .5fr .5fr .5fr;
  grid-gap: 7px;
  
}

.snapchat {
  background-image: url("./assets/icons/snapchatcircle.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 5%;
}

.facebook {
  background-image: url("./assets/icons/facebookcircle.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 5%;
}

.linkedin {
  background-image: url("./assets/icons/linkedincircle.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 5%;
}
.instagram {
  background-image: url("./assets/icons/instagramcircle.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 5%;
}
.slides {
  position: relative;
  min-width: 960px;
  border-radius: 20px;
  top: 50%;
  align-items: center;
}

.click_color {
  color: red;
}

.button_link {
  font-size: 25px;
  letter-spacing: 0.1em;
  font-family: "Parisian" ;
  font-weight: lighter;
  text-decoration: none;
  text-align: left;
  right: 100px;
  color:#768770;
  
}

.button_link:hover, .about_me_paragraph:hover, .project_paragraph:hover, a:hover{
  color: white;
}

/* scroll */

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.snapchat:hover, .facebook:hover, .instagram:hover, .linkedin:hover{
  opacity: .6;
  transform: scale(1.1);
}


html body [data-ca3_iconfont="ETmodules"]::before {
  font-family: "ETmodules";
}
[data-ca3_icon]::before {
  font-weight: normal;
  content: attr(data-ca3_icon);
}

.ca3-scroll-down-arrow {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
	background-size: contain;
	background-repeat: no-repeat;
}

.ca3-scroll-down-link {
  cursor:pointer;
	height: 100px;
	width: 80px;
	margin: 0px 0 0 -40px;
	line-height: 60px;
	position: absolute;
	left: 50%;
	bottom: 0px;
	color: rgb(60, 78, 59);
	text-align: center;
	font-size: 70px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

	-webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
	-moz-animation:    ca3_fade_move_down 2s ease-in-out infinite;
	animation:         ca3_fade_move_down 2s ease-in-out infinite;
}


/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
  0%   { -webkit-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,20px); opacity: 0; }
}
@-moz-keyframes ca3_fade_move_down {
  0%   { -moz-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,20px); opacity: 0; }
}
@keyframes ca3_fade_move_down {
  0%   { transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,20px); opacity: 0; }
}

@media screen and (min-device-width: 200px) and (max-device-width: 600px){

  .my_pic{
    height: 225px;
    width: 225px;
  }
  
  .email_design {
    font-size: 15px;
  }

  .snapchat, .linkedin, .facebook, .instagram{
    width: 25px;
    height: 25px;

  }
  
  .header {
    font-size: 60px;
  }
}

@media screen and (min-device-width: 950px) {
  .menu_resized{
    display: none;

  }
}

@media screen and (max-device-width: 950px) {
  .main_page_header{
    visibility: hidden;
  }
}
