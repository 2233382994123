.popup {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 30vh;
    height: 13vh;
    margin-right: 10px;
    margin-top: 3%;
    display: flex;

    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    background-color: darkgoldenrod;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
    opacity: 0;
    transition: var(--speed) ease;
  } 
  
  100% {
    transform: translateX(0%);
    opacity: 1;
    transition: var(--speed) ease;
  }
}