.header_argrab_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/argrabhugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
  
  .header_hogwarts_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/hogwartshousehugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
  
  .header_hologram_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/holographicuihugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
  
  .header_paperfootball_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/paperfootballhugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }

  .header_portaldevice_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/portaldevicehugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
  
  .header_snowcannon_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/snowdayhugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
 

  .header_vrmmorpg_bk_image {
    margin: auto;
    width: 100%;  
    height: 800px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/ProjectImagesHuge/vrmmorpghugeimagecompress.jpg);
    background-attachment: fixed;
    z-index: -1;
  }
  

  