.about_information_app {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background: linear-gradient(109.6deg, rgb(0,120,110) 11.2%, rgb(224, 235, 213) 91.1%);   
    text-align: center;

        /* Hide scrollbar without taking up space */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .about_content_container{
    /* min-height: 100vh; */
    max-width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10%;
    margin-left: 10%;
    margin-right: 0;
    margin-bottom: 15%; 

    
    /* Hide scrollbar without taking up space */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
